export const useStorage = () => {

    const storage = {
        set: (key: string, value: string) => { sessionStorage.setItem(key, value); },
        get: (key: string) => { return sessionStorage.getItem(key); },
        remove: (key: string) => { sessionStorage.removeItem(key); },
        measure: () => { return sessionStorage.length; },
        clear: () => { sessionStorage.clear(); },
        save: (data: any) => { sessionStorage.setItem('login', data.user.email); sessionStorage.setItem('jwt', data.token); sessionStorage.setItem('authority', data.user.authority);}
    }; 
  
    return { storage };
};