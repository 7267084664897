import React, { useState } from 'react';
import { Steps } from 'antd';
import './Dashboard.scss';
import { steps } from '../../utils/dashboard';
import Main from '../../components/BlockElement/Main/Main';
import { useAuthorization } from '../../hooks/useAuthorization';
import { useSite } from '../../hooks/useSite';
import { useSteps } from '../../hooks/useSteps';
import Site from '../../components/Site/Site';
import Text from '../../components/Text/Text';
import { useText } from '../../hooks/useText';
import Operation from '../../components/Operation/Operation';

export default function Dashboard(){
    const { site, setSite, sites, addSite, deleteSite } = useSite();
    const { text, setText, texts, addText, deleteText } = useText(site);
    const { current, next, prev } = useSteps(0);
    const [ops, setOps] = useState('');
    useAuthorization();

    return(
        <Main className='dashboard'>
            <Steps current={current} items={steps} />
            {current === 0 && <Site site={site} setSite={setSite} sites={sites} addSite={addSite} deleteSite={deleteSite} next={next} />}
            {current === 1 && <Operation val={ops} setVal={setOps} next={next} prev={prev} />}
            {current === 2 && <Text site={site} ops={ops} text={text} setText={setText} texts={texts} addText={addText} deleteText={deleteText} prev={prev} />}
        </Main>
    );
}