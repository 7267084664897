import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../utils/urls';
import { jwtDecode } from 'jwt-decode';
import { useStatus } from './useStatus';
import { useStorage } from './useStorage';
import { useCallback, useEffect } from 'react';

export const useAuthorization = (access?: boolean) => {
  const { loading, error, manager } = useStatus();
  const { storage } = useStorage();
  const navigate = useNavigate();

  const signin = async (credentials: { email: string, password: string }) => {
    if(storage.measure() === 3 && storage.get('jwt')){ navigate('/dashboard'); }
    try {
        manager.load(true);
        const response = await axios.post(`${url}/api/public/auth/signin`, { email: credentials.email, password: credentials.password });
        storage.save(response.data);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        navigate('/dashboard');
    } catch (error: any) {
      console.log('Require: useAuthorization() ...', error);
      manager.error("Your credentials are incorrect!");
    } finally{
      manager.load(false);
    }
  };

  const signout = () => {
    storage.clear();
    delete axios.defaults.headers.common['Authorization'];
    navigate('/');
  };

  const validate = useCallback(() => {
    try {
      const currentDate = new Date();
      const token = storage.get('jwt');
      if(!token){ throw new Error('No token available!'); }
      const decodedToken = jwtDecode(token);
      if(!decodedToken || !decodedToken.exp){ throw new Error('Decoding went wrong'); }
      const expDate = new Date(decodedToken.exp * 1000);
      if (expDate < currentDate){ throw new Error("Token expired!"); }
    } catch (error: any) {
      console.log('Require: useAuthorization() ...', error);
      navigate('/');
    }
  }, [storage, navigate]);

  useEffect(() => {
    if(!access) { validate(); }
  }, [access, validate]);
    
  return { loading, error, signin, signout, validate };
};