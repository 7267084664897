import React, { Fragment } from "react";
import "./Page.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Navbar from "../Navbar/Navbar";
import Footbar from "../Footbar/Footbar";
import Main from "../../BlockElement/Main/Main";

export default function Page({ children }: IStructureElement){
    return(
        <Fragment>
            <Navbar />
            <Main>{children}</Main>
            <Footbar />
        </Fragment>
    );
}