import React from "react";
import './Layout.scss';
import Page from "../../components/StructureElement/Page/Page";
import { useParams } from "react-router-dom";
import Article from "../../components/BlockElement/Article/Article";
import Hero from "../../components/Hero/Hero";
import { useText } from "../../hooks/useText";

export default function Layout(){
    const { site } = useParams();
    const { mapText } = useText(site ? site : '14');

    return(
        <Page>
            <Hero />
            <Article>{mapText()}</Article>
        </Page>
    );
}