import { useState } from "react";

export const useStatus = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    
    const manager = {
        load: async (status: boolean) => setLoading(status),
        error: (message: string) => setError(message) 
    };
  
    return { loading, error, manager };
};